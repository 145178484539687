import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Tree from "../../../../components/Tree/DownlineTree";
import axios from "axios";
import { BaseUrl } from "../../../../App";
import { Config } from "../../../../utils/Auth";
import Spinners from "../../../../components/placeholders/Spinners";

export default function DownlineTree() {
  const [searchParams, setSearchParams] = useSearchParams();
  const memberId = searchParams.get("memberId");
  const memberName = searchParams.get("memberName");
  const [loading, setLoading] = useState(true);
  const [sectionExpired, setSectionExpired] = useState(false);
  const [treeData, setTreeData] = useState(null);
  const [totalMembers, setTotalMembers] = useState(0);
  useEffect(() => {
    fetchTreeData();
  }, []);

  const fetchTreeData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BaseUrl}/api/admin/section/tree-node-tree/${memberId}`,
        Config()
      );
      setTreeData(response.data);
      const totalMembers = calculateTotalMembers(response.data);
      setTotalMembers(totalMembers - 1);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSectionExpired(true);
      }
    } finally {
      setLoading(false);
    }
  };

  
  

  const calculateTotalMembers = (node) => {
    let count = 1;

    if (node.children && Array.isArray(node.children)) {
      node.children.forEach((child) => {
        if (!child.isAddButton) {
          count += calculateTotalMembers(child);
        }
      });
    }

    return count;
  };

  if (loading) {
    return (
      <div className="h-20 flex items-center justify-center">
        <Spinners />
      </div>
    );
  }
  
  return (
    <div className="h-screen overflow-hidden ">
      <div className="bg-white w-full p-3 flex gap-10 rounded">
        <div className="flex flex-col px-10 space-y-3">
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Member ID :</span>{" "}
            <span className="text-custom-orange">{memberId}</span>
          </span>
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Member Name :</span>{" "}
            <span className="text-custom-orange">{memberName}</span>
          </span>
        </div>

        <div className="flex flex-col px-10 border-l border-blue-500 space-y-3">
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Level :</span>{" "}
            <span className="text-custom-pink">{treeData?.level}</span>
          </span>{" "}
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Total Downline members :</span>{" "}
            <span className="text-custom-pink">{totalMembers} </span>
          </span>
        </div>
      </div>

      <Tree member={memberId} />
    </div>
  );
}
