import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import IncompleteTree from "../../../../components/Tree/IncompleteTree";
import { BaseUrl } from "../../../../App";
import { Config } from "../../../../utils/Auth";
import Spinners from "../../../../components/placeholders/Spinners";
import axios from "axios";
import ExpiryModal from "../../../../components/modals/ExpiryModal";

export default function IncompleteTee() {
  const [searchParams, setSearchParams] = useSearchParams();
  const memberId = searchParams.get("memberId");
  const memberName = searchParams.get("memberName");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sectionExpired, setSectionExpired] = useState(false);
  const [member, setMember] = useState({});
  useEffect(() => {
    fetchAgentData();
  }, []);

  const fetchAgentData = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(
        `${BaseUrl}/api/admin/agent/agent-preview/${memberId}`,
        Config()
      );
      setMember(res.data);
    } catch (error) {
      setError(error);
      if (error.response.status === 403) {
        setSectionExpired(true);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinners />;
  }

  return (
    <div className="h-screen overflow-hidden ">
        {sectionExpired && <ExpiryModal isOpen={sectionExpired} />}
      <div className="bg-white w-full p-3 flex gap-10 rounded">
        <div className="flex flex-col px-10 space-y-3">
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Member ID :</span>{" "}
            <span className="text-custom-orange">{memberId}</span>
          </span>
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Member Name :</span>{" "}
            <span className="text-custom-orange">{memberName}</span>
          </span>
        </div>

        <div className="flex flex-col px-10 border-l border-blue-500 space-y-3">
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Level :</span>{" "}
            <span className="text-custom-pink">
              {member?.applicantPlacementLevel}
            </span>
          </span>{" "}
          <span className="font-medium">
            {" "}
            <span className="text-blue-500">Total Downline members :</span>{" "}
            <span className="text-custom-pink">{member?.children?.length}</span>
          </span>
        </div>
      </div>

      <IncompleteTree member={memberId} />
    </div>
  );
}
